/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    plugin: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M1 8a7 7 0 112.898 5.673c-.167-.121-.216-.406-.002-.62l1.8-1.8a3.5 3.5 0 004.572-.328l1.414-1.415a.5.5 0 000-.707l-.707-.707 1.559-1.563a.5.5 0 10-.708-.706l-1.559 1.562-1.414-1.414 1.56-1.562a.5.5 0 10-.707-.706l-1.56 1.56-.707-.706a.5.5 0 00-.707 0L5.318 5.975a3.5 3.5 0 00-.328 4.571l-1.8 1.8c-.58.58-.62 1.6.121 2.137A8 8 0 100 8a.5.5 0 001 0"/>',
    },
});
